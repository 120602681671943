import React from "react";
import "pages/Landing/Landing.css";
import { translate } from "components/Translation/Translation";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import landing1 from "images/landing1.jpeg";
import landing3 from "images/landing3.jpg";
import landing4 from "images/landRegistrationandAdministration.jpg";
import drone04 from "images/drone04.jpg";
import drone05 from "images/drone05.jpg";
import video4 from "images/video3.mp4";
import drone01 from "images/drone1.mp4";
import drone02 from "images/drone2.mp4";
import drone06 from "images/drone06.jpg";

function CarouselPage() {
  return (
    <div className="carousel-container">
      <Carousel>
        <Carousel.Item>
          <video
            autoPlay
            muted
            loop
            style={{
              objectFit: "cover",
              width: "100%",
              height: "94vh",
              backgroundColor: "rgb(66 65 67)",
            }}
          >
            <source src={drone02} type="video/mp4" />
            {translate("landing.videoError")}
          </video>
          <Carousel.Caption>
            <h3>{translate("landing.landingTitle")}</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <video
            autoPlay
            muted
            loop
            style={{
              objectFit: "cover",
              width: "100%",
              height: "94vh",
              backgroundColor: "rgb(66 65 67)",
            }}
          >
            <source src={drone01} type="video/mp4" />
            {translate("landing.videoError")}
          </video>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={landing3}
            alt="fourth slide"
            style={{
              objectFit: "cover",
              height: "94vh",
              backgroundColor: "rgb(66 65 67)",
            }}
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={drone05}
            alt="fifth slide"
            style={{
              objectFit: "cover",
              height: "94vh",
              backgroundColor: "rgb(66 65 67)",
            }}
          />
        </Carousel.Item>

        <Carousel.Item>
          <video
            autoPlay
            muted
            loop
            style={{
              objectFit: "cover",
              width: "100%",
              height: "94vh",
              backgroundColor: "rgb(66 65 67)",
            }}
          >
            <source src={video4} type="video/mp4" />
            {translate("landing.videoError")}
          </video>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default CarouselPage;
