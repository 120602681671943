import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "components/Navbar/Navbar";
import { translate } from "components/Translation/Translation";
import ProductCard from "components/Product-Card/ProductCard";

// Topographical Survey and Mapping
const Services_Inner_5 = () => {
  const services_inner_2_title = translate("services_5.services_inner_5_title");
  const services_inner_2_text_0 = translate(
    "services_5.services_inner_5_text_0"
  );
  const services_inner_2_text_1 = translate(
    "services_5.services_inner_5_text_1"
  );
  const services_inner_2_text_2 = translate(
    "services_5.services_inner_5_text_2"
  );
  const services_inner_2_text_3 = translate(
    "services_5.services_inner_5_text_3"
  );
  const services_inner_2_text_0_title = translate(
    "services_5.services_inner_5_text_0_title"
  );
  const services_inner_2_text_1_title = translate(
    "services_5.services_inner_5_text_1_title"
  );
  const services_inner_2_text_2_title = translate(
    "services_5.services_inner_5_text_2_title"
  );
  const services_inner_2_text_3_title = translate(
    "services_5.services_inner_5_text_3_title"
  );
  const completed = translate("completed");

  const products = [
    {
      title: translate("services_5.services_inner_5_card_0_title"),
      customer: translate("services_5.services_inner_5_card_0_customer"),
      area: translate("services_5.services_inner_5_card_0_area"),
      date: translate("services_5.services_inner_5_card_0_date"),
      location: "Karamehmet/Menteşe",
    },
    {
      title: translate("services_5.services_inner_5_card_1_title"),
      customer: translate("services_5.services_inner_5_card_1_customer"),
      area: translate("services_5.services_inner_5_card_1_area"),
      date: translate("services_5.services_inner_5_card_1_date"),
      location: "Çırpı/Menteşe",
    },
    {
      title: translate("services_5.services_inner_5_card_2_title"),
      customer: translate("services_5.services_inner_5_card_2_customer"),
      area: translate("services_5.services_inner_5_card_2_area"),
      date: translate("services_5.services_inner_5_card_2_date"),
      location: "Karabağlar/Menteşe",
    }
  ];

  const [numCardsToShow, setNumCardsToShow] = useState(6);

  return (
    
    <div>
      <Navbar isHomePage={false} />

      <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
        {services_inner_2_title}
      </h2>
      <div className="flex justify-center">
        <div className="mt-4 w-24 border-b-4 border-blue-900"></div>
      </div>

      <ul className="ml-10 mt-10 list-disc pl-5">
        <li className="mb-2">
          <h3 className="font-bold ">{services_inner_2_text_0_title}</h3>
          {services_inner_2_text_0}
        </li>
        <li className="mb-2">
          <h3 className="font-bold ">{services_inner_2_text_1_title}</h3>
          {services_inner_2_text_1}
        </li>
        <li className="mb-2">
          <h3 className="font-bold ">{services_inner_2_text_2_title}</h3>
          {services_inner_2_text_2}
        </li>
        <li className="mb-2">
          <h3 className="font-bold ">{services_inner_2_text_3_title}</h3>
          {services_inner_2_text_3}
        </li>
      </ul>

      <div id="services" className="bg-gray-100 py-12">
        <section data-aos="zoom-in-down">
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            {completed}
          </h2>
          <div className="flex justify-center">
            <div className="mt-4 w-24 border-b-4 border-blue-900"></div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 overflow-y-auto h-fit m-4">
            {products.slice(0, numCardsToShow).map((product, index) => (
              <ProductCard
                key={index}
                title={product.title}
                customer={product.customer}
                area={product.area}
                date={product.date}
                location={product.location}
              />
            ))}
          </div>
          
          
        </section>
      </div>
    </div>
  );
};
export default Services_Inner_5;

/*
<div className="flex justify-center">
            {products.length > numCardsToShow ? (
              <button
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setNumCardsToShow(numCardsToShow + 6)}
              >
                {translate("buttons.show_more")}
              </button>
            ) : (
              <button
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setNumCardsToShow(6)} // assuming 6 is the initial number of cards to show
              >
                {translate("buttons.show_less")}
              </button>
            )}
          </div>
          */