import React from "react";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import NotFound from "pages/NotFound/NotFound";
import { useDocTitle } from "components/CustomHook";
import { translate } from "components/Translation/Translation";
import AppRoutes from "routes/Routes";
import "index.css";

function App() {
  const page_title = translate("page_title");

  useDocTitle(page_title);

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;

//TODO:
// istatistik adı
// çizgiler alt üst
// görüntü, video
// vizyon misyon metinleri
// about title
// service image
// url0 lang problem

// landing, services, slider,footer,navbar,scrolltop
// products show user is clickble
// stats page gradient
// footer mobile size
// footer mobile map icon
{
  /* <Navbar />
      <Landing />
      <WhyUs />
      <Services />
      <About />
      <Products />
      <Partner />
      <Footer /> */
}

{
  /*
https://www.freepik.com/free-vector/ecology-industrial-infographic_766014.htm#fromView=search&page=2&position=22&uuid=01f72b98-2e83-4a90-b109-ee99543b70cb
https://www.freepik.com/free-vector/interesting-topographic-map-background_9176929.htm#fromView=search&page=3&position=11&uuid=050a605c-3a8a-4c27-bd0c-bf4927f0aaaa
https://www.freepik.com/free-vector/land-survey-civil-engineer-working_1311331.htm#fromView=search&page=5&position=1&uuid=15b3f522-4d3f-4b0d-b6a2-50c766c031a1
https://www.freepik.com/free-vector/lidar-technologies-isometric-background-with-drone-flying-construction-site-3d-vector-illustration_32743823.htm#fromView=search&page=4&position=5&uuid=15b3f522-4d3f-4b0d-b6a2-50c766c031a1
https://www.freepik.com/free-vector/tiny-people-standing-near-earth-globe-flat-illustration_13146677.htm#fromView=search&page=4&position=43&uuid=15b3f522-4d3f-4b0d-b6a2-50c766c031a1

**********************************

https://grid.cz/
https://www.surveying.cl/

**********************************

https://www.freepik.com/premium-video/network-connections-against-3d-city-model-spinning-blue-background_1937117#fromView=search&page=1&position=9&uuid=0d377e6a-e101-40b2-ba71-67f926d63cbe
https://www.freepik.com/premium-video/futuristic-3d-rendering-rotating-holographic-city-digitally-generated-image-virtual-reality-matrix-particles-simulation-cyber-space-background-environment-head-up-display-screen_1008455#fromView=search&page=1&position=41&uuid=0d377e6a-e101-40b2-ba71-67f926d63cbe
https://www.freepik.com/premium-video/aerial-orbit-shot-digital-motion-graphic-showing-construction-site-structure-top-new-skyscraper-building-hong-kong-city_977114#fromView=search&page=2&position=21&uuid=0d377e6a-e101-40b2-ba71-67f926d63cbe
https://www.freepik.com/premium-video/aerial-top-down-shot-henderson-construction-side-building-phase-with-digital-motion-graphic-hong-kong-city-financial-district_962965#fromView=search&page=2&position=28&uuid=0d377e6a-e101-40b2-ba71-67f926d63cbe
https://www.freepik.com/premium-video/shadow-silhouette-drone-flying-grass-field-into-treeline_826832#fromView=search&page=1&position=46&uuid=364086f2-cc5b-4aaa-8bff-9f74d1b7aeab
https://www.freepik.com/premium-video/futuristic-graphic-use-interface-head-up-display-with-virtual-holographic-terrain-relief-motion-elements-background-screen-display_1118561#fromView=resource_detail&position=15&from_element=related_resources
https://www.freepik.com/premium-video/advance-motion-graphic-holographic-terrain-environment-geomorphology-topography-digital-data-telemetry-information-display-screen-background_810261#fromView=resource_detail&position=0&from_element=related_resources
https://www.freepik.com/free-photo/drone-flying-field-corn-field_135009585.htm#fromView=search&page=1&position=40&uuid=10f4a393-f2cd-4242-a342-568676e6cf9b

*/
  //https://www.freepik.com/free-ai-image/abstract-render-drone-wallpaper_122396386.htm#fromView=search&page=3&position=6&uuid=d019cdb0-56b4-406f-a320-ca9f5413dfb9
}
