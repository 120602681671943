import React from "react";
import About from "pages/About/About";
import Landing from "pages/Landing/Landing";
import Partner from "pages/Partner/Partner";
import Services from "components/Services/Services";
import Navbar from "components/Navbar/Navbar.jsx";
import Footer from "components/Footer/Footer";
import StatsSection from "components/StatsSection/StatsSection";
import ScrollTop from "components/ScrollTop/ScrollTop";
import "./Main.css";

function Main() {
  return (
    <div className="Main">
      <Navbar isHomePage={true} />
      <div id="landing">
        <Landing />
      </div>
      <ScrollTop />
      <div className="class_whuys" id="whychooseus">
        <StatsSection />
      </div>

      <div id="services">
        <Services />
      </div>

      <div className="class_about" id="about">
        <About />
      </div>
      <div id="partner">
        <Partner />
      </div>

      <div id="footer" className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default Main;
