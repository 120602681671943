import React from "react";
import "pages/Partner/Partner.css";
import Slider from "components/Slider/Slider";
import { translate } from "components/Translation/Translation";

function Partner() {
  const partner_title = translate("partner.partner_title");
  return (
    <div>
      <div className="flex justify-center">
        <div className="w-24 border-b-4 border-blue-900"></div>
      </div>
      <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
        {partner_title}
      </h2>

      <Slider />
    </div>
  );
}
export default Partner;
