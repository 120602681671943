import React, { useState, useEffect } from "react";
import "components/Navbar/Navbar.css";
import {
  translate,
  availableLanguages,
} from "components/Translation/Translation";
import { Link } from "react-router-dom";

const Navbar = ({ isHomePage }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    return storedLang || "tr";
  });

  const handleNavLinkClick = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
    setMenuOpen(false);
  };

  const handleLanguageChange = (lang) => {
    setSelectedLang(lang);
    localStorage.setItem("selectedLang", lang);
    window.location.reload();
  };

  useEffect(() => {
    const handleLanguageChangeFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const lang = urlParams.get("lang");
      if (lang && availableLanguages.includes(lang)) {
        setSelectedLang(lang);
        localStorage.setItem("selectedLang", lang);
      }
    };

    handleLanguageChangeFromUrl();
  }, []);

  return (
    <nav>
      <a href={`/`} className="title">
        <img src="/logo.png" alt="Epok Logo" onClick={() => "/"} />
      </a>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <img src="/menu_bar2.svg" alt="Menu Icon" width="24" height="24" />
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          {isHomePage ? (
            <a href="#about" className="nav-link">
              {translate("navbar.aboutUs")}
            </a>
          ) : (
            <Link to="/#about" className="nav-link">
              {translate("navbar.aboutUs")}
            </Link>
          )}
        </li>
        <li>
          {isHomePage ? (
            <a href="#services" className="nav-link">
              {translate("navbar.services")}
            </a>
          ) : (
            <Link to="/#services" className="nav-link">
              {translate("navbar.services")}
            </Link>
          )}
        </li>
        <li>
          {isHomePage ? (
            <a href="#whychooseus" className="nav-link">
              {translate("navbar.whyChooseUs")}
            </a>
          ) : (
            <Link to="/#whychooseus" className="nav-link">
              {translate("navbar.whyChooseUs")}
            </Link>
          )}
        </li>

        <li>
          {isHomePage ? (
            <a href="#footer" className="nav-link">
              {translate("navbar.contact")}
            </a>
          ) : (
            <Link to="/#footer" className="nav-link">
              {translate("navbar.contact")}
            </Link>
          )}
        </li>
        <li className="language-toggle">
          {selectedLang === "tr" ? (
            <img
              src="/united-kingdom-flag.svg"
              alt="English Flag"
              onClick={() => handleLanguageChange("en")}
            />
          ) : (
            <img
              src="/turkey-flag.svg"
              alt="Turkish Flag"
              onClick={() => handleLanguageChange("tr")}
            />
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
