import translations from "components/Translation/translations.json";

const availableLanguages = ["tr", "en"];

function translate(key) {
  const selectedLanguage = localStorage.getItem("selectedLang") || "tr";
  if (!availableLanguages.includes(selectedLanguage)) {
    console.warn("Invalid language:", selectedLanguage);
    return key;
  }

  const nestedKeys = key.split(".");
  let translation = translations;
  nestedKeys.forEach((nestedKey) => {
    translation = translation[nestedKey];
  });

  return translation?.[selectedLanguage] || key;
}

export { translate, availableLanguages };
