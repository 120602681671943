import React, { useState, useEffect } from "react";
import "components/Footer/Footer.css";
import { translate } from "components/Translation/Translation";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Footer = () => {
  const footer_services = translate("footer.footer_services");
  const footer_services_0 = translate("footer.footer_services_0");
  const footer_services_1 = translate("footer.footer_services_1");
  const footer_services_2 = translate("footer.footer_services_2");
  const footer_services_3 = translate("footer.footer_services_3");
  const footer_services_4 = translate("footer.footer_services_4");
  const footer_services_5 = translate("footer.footer_services_5");
  const footer_socialmedia = translate("footer.footer_socialmedia");
  const footer_copyright = translate("footer.footer_copyright");
  const footer_privacy = translate("footer.footer_privacy");
  const footer_company = translate("footer.footer_company");
  const footer_aboutus = translate("footer.footer_aboutus");
  const footer_career = translate("footer.footer_career");
  const footer_contact = translate("footer.footer_contact");
  const footer_contactus = translate("footer.footer_contactus");
  const [selectedLang, setSelectedLang] = useState("tr");

  useEffect(() => {
    const handleLanguageChangeFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const lang = urlParams.get("lang");
      if (lang) {
        setSelectedLang(lang);
      }
    };

    handleLanguageChangeFromUrl();
  }, []);

  const handleNavLinkClick = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="footer">
      <div className="sb__footer section__padding">
        <div className="sb__footer-links">
          <div className="sb__footer-links__div">
            <h4 className="footer-h4">{footer_services}</h4>
            <a href="/topographicalsurvey">
              <span className="nav-link"></span>
              <p>{footer_services_0}</p>
            </a>
            <a href="/geodeticnetwork">
              <span className="nav-link"></span>
              <p>{footer_services_1}</p>
            </a>
            <a href="/geographicinformationsystems">
              <span className="nav-link"></span>
              <p>{footer_services_2}</p>
            </a>
            <a href="/landregistry&cadastralengineering">
              <span className="nav-link"></span>
              <p>{footer_services_3}</p>
            </a>
            <a href="/realestate">
              <span className="nav-link"></span>
              <p>{footer_services_4}</p>
            </a>
            <a href="/civilstructuralconstruction">
              <span className="nav-link"></span>
              <p>{footer_services_5}</p>
            </a>
          </div>

          <div className="sb__footer-links__div">
            <h4 className="footer-h4">{footer_company}</h4>
            <a href="#about">
              <span className="nav-link"></span>
              <p>{footer_aboutus}</p>
            </a>
            <a href="https://www.linkedin.com/company/epok-geomatics-international/">
              <p>{footer_career}</p>
            </a>
            <a href="#footer">
              <span className="nav-link"></span>
              <p>{footer_contact}</p>
            </a>
          </div>

          <div className="sb__footer-links__div__contact">
            <h4 className="footer-h4">{footer_contactus}</h4>
            <a href="tel:+902522121050" className="contact-link">
              <div className="contact-info">
                <FaPhone className="contact-icon" />
                <p>+90 252 212 10 50</p>
              </div>
            </a>
            <a href="mailto:info@epok.com.tr" className="contact-link">
              <div className="contact-info">
                <FaEnvelope className="contact-icon" />
                <p>info@epok.com.tr</p>
              </div>
            </a>
            <div className="contact-link">
              <div className="contact-info">
                <FaMapMarkerAlt className="contact-icon" />
                <p>
                  Emirbeyazit Mahallesi, Çaylılar Sokak, Konak Apartmanı No:1
                  K:1 D:2 Menteşe / Muğla / Turkey
                </p>
              </div>
            </div>
          </div>

          <div className="sb__footer-links__div__social">
            <h4 className="footer-h4">{footer_socialmedia}</h4>
            <div className="socialmedia">
              <p>
                <a href="https://www.linkedin.com/company/epok-geomatics-international/">
                  <img src="./linkedin-50.svg" alt="LinkedIn" />
                </a>
              </p>
              <p>
                <a href="https://www.instagram.com/epokgeomatics">
                  <img src="/instagram-50.svg" alt="Instagram" />
                </a>
              </p>
              <p>
                <a href="https://www.facebook.com/profile.php?id=100063737311225">
                  <img src="/facebook-48.svg" alt="Facebook" />
                </a>
              </p>
            </div>
          </div>
        </div>

        <hr></hr>

        <div className="sb__footer-below">
          <div className="sb__footer-copyright">
            <p>
              Copyright © {new Date().getFullYear()}
              &nbsp;{footer_copyright}
            </p>
          </div>
          <div className="sb__footer-below-links">
            <a href="./privacyPolicy.pdf">
              <div>
                <p>{footer_privacy}</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
