import React from "react";
import { translate } from "components/Translation/Translation";
import "pages/NotFound/NotFound.css";

const NotFound = () => {
  const notfound_title = translate("notfound.notfound_title");
  const notfound_message = translate("notfound.notfound_message");
  const notfound_button = translate("notfound.notfound_button");

  return (
    <div className="not-found">
      <div
        className="flex items-center justify-center min-h-screen bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: "url('/404.svg')" }}
      >
        <div className="max-w-md mx-auto text-center bg-white bg-opacity-90 p-8 rounded-lg shadow-lg">
          <div className="text-9xl font-bold text-indigo-600 mb-4">404</div>
          <h1 className="text-4xl font-bold text-gray-800 mb-6">
            {notfound_title}
          </h1>
          <p className="text-lg text-gray-600 mb-8">{notfound_message}</p>
          <a
            href={`/`}
            className="inline-block bg-indigo-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-indigo-700 transition-colors duration-300"
          >
            {notfound_button}
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
