import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Main from "pages/Main/Main";
import ServicesInner0 from "pages/Services_Inner_0/Services_Inner_0";
import ServicesInner1 from "pages/Services_Inner_1/Services_Inner_1";
import ServicesInner2 from "pages/Services_Inner_2/Services_Inner_2";
import ServicesInner3 from "pages/Services_Inner_3/Services_Inner_3";
import ServicesInner4 from "pages/Services_Inner_4/Services_Inner_4";
import ServicesInner5 from "pages/Services_Inner_5/Services_Inner_5";
import NotFound from "pages/NotFound/NotFound";

const AppRoutes = () => {
  const [validURL, setValidURL] = useState(true);

  useEffect(() => {
    const isValidURL = checkURLValidity();
    setValidURL(isValidURL);
  }, []);

  const checkURLValidity = () => {
    const validURLs = [
      "http://localhost:3000/",
      "http://localhost:3000/topographicalsurvey",
      "http://localhost:3000/geodeticnetwork",
      "http://localhost:3000/geographicinformationsystems",
      "http://localhost:3000/landregistry&cadastralengineering",
      "http://localhost:3000/realestate",
      "http://localhost:3000/civilstructuralconstruction",
      "http://localhost:3000/#about",
      "http://localhost:3000/#footer",
      "http://localhost:3000/#services",
      "http://localhost:3000/#whychooseus",
      "http://192.168.1.105:3000",
      "https://epok.com.tr/",
      "https://epok.com.tr/topographicalsurvey",
      "https://epok.com.tr/geodeticnetwork",
      "https://epok.com.tr/geographicinformationsystems",
      "https://epok.com.tr/landregistry&cadastralengineering",
      "https://epok.com.tr/realestate",
      "https://epok.com.tr/civilstructuralconstruction",
      "https://epok.com.tr/#about",
      "https://epok.com.tr/#footer",
      "https://epok.com.tr/#services",
      "https://epok.com.tr/#whychooseus",
      "http://epok.com.tr/",
      "http://epok.com.tr/topographicalsurvey",
      "http://epok.com.tr/geodeticnetwork",
      "http://epok.com.tr/geographicinformationsystems",
      "http://epok.com.tr/landregistry&cadastralengineering",
      "http://epok.com.tr/realestate",
      "http://epok.com.tr/civilstructuralconstruction",
      "http://epok.com.tr/#about",
      "http://epok.com.tr/#footer",
      "http://epok.com.tr/#services",
      "http://epok.com.tr/#whychooseus",
      "https://www.epok.com.tr/",
      "https://www.epok.com.tr/topographicalsurvey",
      "https://www.epok.com.tr/geodeticnetwork",
      "https://www.epok.com.tr/geographicinformationsystems",
      "https://www.epok.com.tr/landregistry&cadastralengineering",
      "https://www.epok.com.tr/realestate",
      "https://www.epok.com.tr/civilstructuralconstruction",
      "https://www.epok.com.tr/#about",
      "https://www.epok.com.tr/#footer",
      "https://www.epok.com.tr/#services",
      "https://www.epok.com.tr/#whychooseus",
      "http://www.epok.com.tr/",
      "http://www.epok.com.tr/topographicalsurvey",
      "http://www.epok.com.tr/geodeticnetwork",
      "http://www.epok.com.tr/geographicinformationsystems",
      "http://www.epok.com.tr/landregistry&cadastralengineering",
      "http://www.epok.com.tr/realestate",
      "http://www.epok.com.tr/civilstructuralconstruction",
      "http://www.epok.com.tr/#about",
      "http://www.epok.com.tr/#footer",
      "http://www.epok.com.tr/#services",
      "http://www.epok.com.tr/#whychooseus",
      "*/topographicalsurvey",
      "*/geodeticnetwork",
      "*/geographicinformationsystems",
      "*/landregistry&cadastralengineering",
      "*/realestate",
      "*/civilstructuralconstruction",
      "*/#about",
      "*/#footer",
      "*/#services",
      "*/#whychooseus",
      "*/",
    ];
    return validURLs.includes(window.location.href);
  };

  if (!validURL) {
    return <NotFound />;
  }

  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/topographicalsurvey" element={<ServicesInner0 />} />
      <Route path="/geodeticnetwork" element={<ServicesInner1 />} />
      <Route
        path="/geographicinformationsystems"
        element={<ServicesInner2 />}
      />
      <Route
        path="/landregistry&cadastralengineering"
        element={<ServicesInner3 />}
      />
      <Route path="/realestate" element={<ServicesInner4 />} />
      <Route path="/civilstructuralconstruction" element={<ServicesInner5 />} />
    </Routes>
  );
};

export default AppRoutes;
