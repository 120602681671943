import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "components/Navbar/Navbar";
import { translate } from "components/Translation/Translation";
import ProductCard from "components/Product-Card/ProductCard";

// GIS
const Services_inner_3 = () => {
  const customers = "300+";
  const countries = "1+";
  const projects = "1000+";
  const duration = 5;

  const services_inner_3_title = translate("services_3.services_inner_3_title");
  const services_inner_3_text_0 = translate(
    "services_3.services_inner_3_text_0"
  );
  const services_inner_3_text_1 = translate(
    "services_3.services_inner_3_text_1"
  );
  const services_inner_3_text_2 = translate(
    "services_3.services_inner_3_text_2"
  );
  const services_inner_3_text_3 = translate(
    "services_3.services_inner_3_text_3"
  );
  const services_inner_3_text_4 = translate(
    "services_3.services_inner_3_text_4"
  );
  const services_inner_3_text_5 = translate(
    "services_3.services_inner_3_text_5"
  );

  const services_inner_3_text_0_title = translate(
    "services_3.services_inner_3_text_0_title"
  );
  const services_inner_3_text_1_title = translate(
    "services_3.services_inner_3_text_1_title"
  );
  const services_inner_3_text_2_title = translate(
    "services_3.services_inner_3_text_2_title"
  );
  const services_inner_3_text_3_title = translate(
    "services_3.services_inner_3_text_3_title"
  );
  const services_inner_3_text_4_title = translate(
    "services_3.services_inner_3_text_4_title"
  );
  const services_inner_3_text_5_title = translate(
    "services_3.services_inner_3_text_5_title"
  );

  const stats_customers = translate("stats.stats_customers");
  const stats_countries = translate("stats.stats_countries");
  const stats_projects = translate("stats.stats_projects");
  const stats_years = translate("stats.stats_years");
  const stats_page_title = translate("stats.stats_page_title");

  const [currentCustomers, setCurrentCustomers] = useState(0);
  const [currentCountries, setCurrentCountries] = useState(0);
  const [currentProjects, setCurrentProjects] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const targetCustomers = parseInt(customers.replace("+", ""));
  const targetCountries = parseInt(countries);
  const targetProjects = parseInt(projects.replace("+", ""));

  const statsRef = useRef(null);

  const handleScroll = () => {
    if (
      statsRef.current &&
      window.scrollY + window.innerHeight >= statsRef.current.offsetTop
    ) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const customersIncrement = Math.ceil(
        (targetCustomers - currentCustomers) / (duration * 1)
      );
      const countriesIncrement = Math.ceil(
        (targetCountries - currentCountries) / (duration * 10)
      );
      const projectsIncrement = Math.ceil(
        (targetProjects - currentProjects) / (duration * 1)
      );

      const intervalId = setInterval(() => {
        setCurrentCustomers((value) =>
          Math.min(value + customersIncrement, targetCustomers)
        );
        setCurrentCountries((value) =>
          Math.min(value + countriesIncrement, targetCountries)
        );
        setCurrentProjects((value) =>
          Math.min(value + projectsIncrement, targetProjects)
        );
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, [
    isVisible,
    customers,
    countries,
    projects,
    duration,
    currentCustomers,
    currentCountries,
    currentProjects,
    targetCustomers,
    targetCountries,
    targetProjects,
  ]);

  return (
    <div>
      <Navbar isHomePage={false} />
     
       
      <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
      {services_inner_3_title}
    </h2>
    <div className="flex justify-center">
      <div className="mt-4 w-24 border-b-4 border-blue-900"></div>
    </div>
    <div className="mx-auto max-w-7xl">
      <ul className="ml-10 mt-10 list-disc pl-5">
        <li className="mb-2">
          <h3 className="font-bold">{services_inner_3_text_0_title}</h3>
          {services_inner_3_text_0}
        </li>
        <li className="mb-2">
          <h3 className="font-bold">{services_inner_3_text_1_title}</h3>
          {services_inner_3_text_1}
        </li>
        <li className="mb-2">
          <h3 className="font-bold">{services_inner_3_text_2_title}</h3>
          {services_inner_3_text_2}
        </li>
        <li className="mb-2">
          <h3 className="font-bold">{services_inner_3_text_3_title}</h3>
          {services_inner_3_text_3}
        </li>
        <li className="mb-2">
          <h3 className="font-bold">{services_inner_3_text_4_title}</h3>
          {services_inner_3_text_4}
        </li>
        <li className="mb-2">
          <h3 className="font-bold">{services_inner_3_text_5_title}</h3>
          {services_inner_3_text_5}
        </li>
      </ul>
    </div>
    <div
      className="stats-section w-full h-screen max-h-[50rem] p-8 sm:p-12 md:p-16 lg:p-24 relative overflow-hidden block z-5"
      ref={statsRef}
    >
        <div className="top2">
          <div className="flex justify-center">
            <div className="w-8 sm:w-12 border-b-2 sm:border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-6 sm:mx-12 text-center text-lg sm:text-xl lg:text-2xl font-semibold text-blue-900">
            {stats_page_title}
          </h2>
        </div>
        <section className="text-gray-700 body-font">
          <div className="container px-5 py-12 mx-auto">
            <div className="flex flex-wrap -m-4 text-center">
              <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                <div className="h-full border-2 border-gray-600 px-4 py-6 rounded-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    class="text-blue-700 w-12 h-12 mb-3 inline-block"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                    />
                  </svg>
                  <h2 className="title-font font-medium text-3xl text-gray-900">
                    {currentProjects}
                  </h2>
                  <p className="leading-relaxed">{stats_projects}</p>
                </div>
              </div>
              <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                <div className="h-full border-2 border-gray-600 px-4 py-6 rounded-lg">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="text-blue-700 w-12 h-12 mb-3 inline-block"
                    viewBox="0 0 24 24"
                  >
                    <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                  </svg>
                  <h2 className="title-font font-medium text-3xl text-gray-900">
                    {currentCustomers}
                  </h2>
                  <p className="leading-relaxed">{stats_customers}</p>
                </div>
              </div>
              <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                <div className="h-full border-2 border-gray-600 px-4 py-6 rounded-lg">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="text-blue-700 w-12 h-12 mb-3 inline-block"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                    />
                  </svg>
                  <h2 className="title-font font-medium text-3xl text-gray-900">
                    20+
                  </h2>
                  <p className="leading-relaxed">{stats_years}</p>
                </div>
              </div>
              <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                <div className="h-full border-2 border-gray-600 px-4 py-6 rounded-lg">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="text-blue-700 w-12 h-12 mb-3 inline-block"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m20.893 13.393-1.135-1.135a2.252 2.252 0 0 1-.421-.585l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 0 1-1.383-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.411-2.353a2.25 2.25 0 0 0 .286-.76m11.928 9.869A9 9 0 0 0 8.965 3.525m11.928 9.868A9 9 0 1 1 8.965 3.525"
                    />
                  </svg>
                  <h2 className="title-font font-medium text-3xl text-gray-900">
                    {currentCountries}
                  </h2>
                  <p className="leading-relaxed">{stats_countries}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Services_inner_3;
