import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "components/Navbar/Navbar";
import { translate } from "components/Translation/Translation";
import ProductCard from "components/Product-Card/ProductCard";

// Topographical Survey and Mapping
const Services_Inner_0 = () => {
  const services_inner_0_title = translate("services_0.services_inner_0_title");
  const services_inner_0_text_0 = translate(
    "services_0.services_inner_0_text_0"
  );
  const services_inner_0_text_1 = translate(
    "services_0.services_inner_0_text_1"
  );
  const services_inner_0_text_2 = translate(
    "services_0.services_inner_0_text_2"
  );
  const services_inner_0_text_3 = translate(
    "services_0.services_inner_0_text_3"
  );
  const services_inner_0_text_0_title = translate(
    "services_0.services_inner_0_text_0_title"
  );
  const services_inner_0_text_1_title = translate(
    "services_0.services_inner_0_text_1_title"
  );
  const services_inner_0_text_2_title = translate(
    "services_0.services_inner_0_text_2_title"
  );
  const services_inner_0_text_3_title = translate(
    "services_0.services_inner_0_text_3_title"
  );
  const completed = translate("completed");

  const products = [
    {
      title: translate("services_0.services_inner_0_card_0_title"),
      customer: translate("services_0.services_inner_0_card_0_customer"),
      area: translate("services_0.services_inner_0_card_0_area"),
      date: translate("services_0.services_inner_0_card_0_date"),
      location: "Bodrum",
    },
    {
      title: translate("services_0.services_inner_0_card_1_title"),
      customer: translate("services_0.services_inner_0_card_1_customer"),
      area: translate("services_0.services_inner_0_card_1_area"),
      date: translate("services_0.services_inner_0_card_1_date"),
      location: "Bodrum",
    },
    {
      title: translate("services_0.services_inner_0_card_2_title"),
      customer: translate("services_0.services_inner_0_card_2_customer"),
      area: translate("services_0.services_inner_0_card_2_area"),
      date: translate("services_0.services_inner_0_card_2_date"),
      location: "Marmaris",
    },
    {
      title: translate("services_0.services_inner_0_card_3_title"),
      customer: translate("services_0.services_inner_0_card_3_customer"),
      area: translate("services_0.services_inner_0_card_3_area"),
      date: translate("services_0.services_inner_0_card_3_date"),
      location: "Muğla",
    },
    {
      title: translate("services_0.services_inner_0_card_4_title"),
      customer: translate("services_0.services_inner_0_card_4_customer"),
      area: translate("services_0.services_inner_0_card_4_area"),
      date: translate("services_0.services_inner_0_card_4_date"),
      location: "Menteşe",
    },
    {
      title: translate("services_0.services_inner_0_card_5_title"),
      customer: translate("services_0.services_inner_0_card_5_customer"),
      area: translate("services_0.services_inner_0_card_5_area"),
      date: translate("services_0.services_inner_0_card_5_date"),
      location: "Bodrum",
    },
    {
      title: translate("services_0.services_inner_0_card_6_title"),
      customer: translate("services_0.services_inner_0_card_6_customer"),
      area: translate("services_0.services_inner_0_card_6_area"),
      date: translate("services_0.services_inner_0_card_6_date"),
      location: "Muğla",
    },
    {
      title: translate("services_0.services_inner_0_card_7_title"),
      customer: translate("services_0.services_inner_0_card_7_customer"),
      area: translate("services_0.services_inner_0_card_7_area"),
      date: translate("services_0.services_inner_0_card_7_date"),
      location: "Muğla",
    },
    {
      title: translate("services_0.services_inner_0_card_8_title"),
      customer: translate("services_0.services_inner_0_card_8_customer"),
      area: translate("services_0.services_inner_0_card_8_area"),
      date: translate("services_0.services_inner_0_card_8_date"),
      location: "Marmaris",
    },
    {
      title: translate("services_0.services_inner_0_card_9_title"),
      customer: translate("services_0.services_inner_0_card_9_customer"),
      area: translate("services_0.services_inner_0_card_9_area"),
      date: translate("services_0.services_inner_0_card_9_date"),
      location: "Alanya",
    },
    {
      title: translate("services_0.services_inner_0_card_10_title"),
      customer: translate("services_0.services_inner_0_card_10_customer"),
      area: translate("services_0.services_inner_0_card_10_area"),
      date: translate("services_0.services_inner_0_card_10_date"),
      location: "Muğla",
    },
    {
      title: translate("services_0.services_inner_0_card_11_title"),
      customer: translate("services_0.services_inner_0_card_11_customer"),
      area: translate("services_0.services_inner_0_card_11_area"),
      date: translate("services_0.services_inner_0_card_11_date"),
      location: "Çeşme",
    },
    {
      title: translate("services_0.services_inner_0_card_12_title"),
      customer: translate("services_0.services_inner_0_card_12_customer"),
      area: translate("services_0.services_inner_0_card_12_area"),
      date: translate("services_0.services_inner_0_card_12_date"),
      location: "Nevşehir-Kayseri",
    },
    {
      title: translate("services_0.services_inner_0_card_13_title"),
      customer: translate("services_0.services_inner_0_card_13_customer"),
      area: translate("services_0.services_inner_0_card_13_area"),
      date: translate("services_0.services_inner_0_card_13_date"),
      location: "Adana-Mersin",
    },
    {
      title: translate("services_0.services_inner_0_card_14_title"),
      customer: translate("services_0.services_inner_0_card_14_customer"),
      area: translate("services_0.services_inner_0_card_14_area"),
      date: translate("services_0.services_inner_0_card_14_date"),
      location: "Kırklareli",
    },
    {
      title: translate("services_0.services_inner_0_card_15_title"),
      customer: translate("services_0.services_inner_0_card_15_customer"),
      area: translate("services_0.services_inner_0_card_15_area"),
      date: translate("services_0.services_inner_0_card_15_date"),
      location: "Denizli",
    },
    {
      title: translate("services_0.services_inner_0_card_16_title"),
      customer: translate("services_0.services_inner_0_card_16_customer"),
      area: translate("services_0.services_inner_0_card_16_area"),
      date: translate("services_0.services_inner_0_card_16_date"),
      location: "Muğla",
    },
  ];

  const [numCardsToShow, setNumCardsToShow] = useState(6);

  return (
    <div>
      <Navbar isHomePage={false} />

      <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
        {services_inner_0_title}
      </h2>
      <div className="flex justify-center">
        <div className="mt-4 w-24 border-b-4 border-blue-900"></div>
      </div>

      <ul className="ml-10 mt-10 list-disc pl-5">
        <li className="mb-2">
          <h3 className="font-bold ">{services_inner_0_text_0_title}</h3>
          {services_inner_0_text_0}
        </li>
        <li className="mb-2">
          <h3 className="font-bold ">{services_inner_0_text_1_title}</h3>
          {services_inner_0_text_1}
        </li>
        <li className="mb-2">
          <h3 className="font-bold ">{services_inner_0_text_2_title}</h3>
          {services_inner_0_text_2}
        </li>
        <li className="mb-2">
          <h3 className="font-bold ">{services_inner_0_text_3_title}</h3>
          {services_inner_0_text_3}
        </li>
      </ul>

      <div id="services" className="bg-gray-100 py-12">
        <section data-aos="zoom-in-down">
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            {completed}
          </h2>
          <div className="flex justify-center">
            <div className="mt-4 w-24 border-b-4 border-blue-900"></div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 overflow-y-auto h-fit m-4">
            {products.slice(0, numCardsToShow).map((product, index) => (
              <ProductCard
                key={index}
                title={product.title}
                customer={product.customer}
                area={product.area}
                date={product.date}
                location={product.location}
              />
            ))}
          </div>
          <div className="flex justify-center">
            {products.length > numCardsToShow ? (
              <button
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setNumCardsToShow(numCardsToShow + 6)}
              >
                {translate("buttons.show_more")}
              </button>
            ) : (
              <button
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setNumCardsToShow(6)} // assuming 6 is the initial number of cards to show
              >
                {translate("buttons.show_less")}
              </button>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};
export default Services_Inner_0;
