import React, { useState } from "react";
import { Link } from "react-router-dom";
import threedimmodel from "images/realEstate.jpg";
import Geodetic from "images/Geodetic.svg";
import Topographical from "images/Topographical.svg";
import Geographical from "images/geographical.png";
import Aerial_Photography from "images/landRegistrationandAdministration.jpg";
import Underground from "images/construction.jpeg";
import { translate } from "components/Translation/Translation";

const Services = () => {
  const [showSecondRow, setShowSecondRow] = useState(false);

  const services_page_title = translate("services.services_page_title");
  const services_sub_text = translate("services.services_sub_text");

  const services_first_card_title = translate(
    "services.services_first_card_title"
  );
  const services_first_card_text = translate(
    "services.services_first_card_text"
  );
  const services_second_card_title = translate(
    "services.services_second_card_title"
  );
  const services_second_card_text = translate(
    "services.services_second_card_text"
  );
  const services_third_card_title = translate(
    "services.services_third_card_title"
  );
  const services_third_card_text = translate(
    "services.services_third_card_text"
  );
  const services_fourth_card_title = translate(
    "services.services_fourth_card_title"
  );
  const services_fourth_card_text = translate(
    "services.services_fourth_card_text"
  );
  const services_fifth_card_title = translate(
    "services.services_fifth_card_title"
  );
  const services_fifth_card_text = translate(
    "services.services_fifth_card_text"
  );
  const services_sixth_card_title = translate(
    "services.services_sixth_card_title"
  );
  const services_sixth_card_text = translate(
    "services.services_sixth_card_text"
  );

  const urls = [
    "/topographicalsurvey",
    "/geodeticnetwork",
    "/geographicinformationsystems",
    "/landregistry&cadastralengineering",
    "/realestate",
    "/civilstructuralconstruction",
  ];

  const show_more = translate("buttons.show_more");
  const go_to_projects = translate("buttons.go_to_projects");
  const go_to_details = translate("buttons.go_to_details");
  return (
    <div id="services" className="bg-gray-100 py-12 mt-52">
      <section data-aos="zoom-in-down">
        <div className="top2">
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            {services_page_title}
          </h2>
          <p className="text-center my-4"> {services_sub_text}</p>
        </div>

        <div
      className="px-4 md:px-12 w-full md:w-4/5 mx-auto"
      data-aos="fade-down"
      data-aos-delay="600"
    >
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {[0, 1, 2].map((index) => (
              <div
                key={index}
                className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-black rounded-lg shadow-2xl p-3 group flex items-center justify-center"
              >
                {" "}
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60 w-full object-cover"
                    src={
                      index === 0
                        ? Topographical
                        : index === 1
                        ? Geodetic
                        : Geographical
                    }
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    {index === 0
                      ? services_first_card_title
                      : index === 1
                      ? services_second_card_title
                      : services_third_card_title}
                  </h2>
                  <p className="text-md font-medium">
                    {index === 0
                      ? services_first_card_text
                      : index === 1
                      ? services_second_card_text
                      : services_third_card_text}
                  </p>

                  <button className="block mx-auto mt-4  text-blue-500 hover:text-blue-700 font-bold py-2 px-4 rounded">
                    <Link to={urls[index]}>{go_to_projects}</Link>
                  </button>
                </div>
              </div>
            ))}
            {showSecondRow &&
              [3, 4, 5].map((index) => (
                <div
                  key={index}
                  className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-black rounded-lg shadow-2xl p-3 group flex items-center justify-center"
                >
                  {" "}
                  <div className="m-2 text-justify text-sm">
                    <img
                      alt="card img"
                      className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60 w-full object-cover"
                      src={
                        index === 3
                          ? Aerial_Photography
                          : index === 4
                          ? threedimmodel
                          : Underground
                      }
                    />
                    <h2 className="font-semibold my-4 text-2xl text-center">
                      {index === 3
                        ? services_fourth_card_title
                        : index === 4
                        ? services_fifth_card_title
                        : services_sixth_card_title}
                    </h2>
                    <p className="text-md font-medium">
                      {index === 3
                        ? services_fourth_card_text
                        : index === 4
                        ? services_fifth_card_text
                        : services_sixth_card_text}
                    </p>
                    <button className="block mx-auto mt-4 text-blue-500 hover:text-blue-700 font-bold py-2 px-4 rounded">
                      <Link to={urls[index]}>
                        {" "}
                        {index === 3 || index === 4
                          ? go_to_details
                          : go_to_projects}
                      </Link>
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="text-center mt-4">
            <button
              onClick={() => setShowSecondRow(!showSecondRow)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {showSecondRow
                ? translate("buttons.hide")
                : translate("buttons.show_more_2")}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
