import React, { useState, useEffect, useRef } from "react";
import "components/StatsSection/StatsSection.css";
import { translate } from "components/Translation/Translation";

const StatsSection = () => {
  const customers = "3000+";
  const countries = "10+";
  const projects = "3500+";
  const duration = 3;

  const stats_customers = translate("stats.stats_customers");
  const stats_countries = translate("stats.stats_countries");
  const stats_projects = translate("stats.stats_projects");
  const stats_years = translate("stats.stats_years");
  const stats_page_title = translate("stats.stats_page_title");
  const stats_section_card4_title = translate(
    "stats.stats_section_card4_title"
  );
  const stats_section_card4_text = translate("stats.stats_section_card4_text");
  const stats_section_card5_title = translate(
    "stats.stats_section_card5_title"
  );
  const stats_section_card5_text = translate("stats.stats_section_card5_text");
  const stats_section_card6_title = translate(
    "stats.stats_section_card6_title"
  );
  const stats_section_card6_text = translate("stats.stats_section_card6_text");
  const stats_section_card7_title = translate(
    "stats.stats_section_card7_title"
  );
  const stats_section_card7_text = translate("stats.stats_section_card7_text");

  const [currentCustomers, setCurrentCustomers] = useState(0);
  const [currentCountries, setCurrentCountries] = useState(0);
  const [currentProjects, setCurrentProjects] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const targetCustomers = parseInt(customers.replace("+", ""));
  const targetCountries = parseInt(countries);
  const targetProjects = parseInt(projects.replace("+", ""));

  const statsRef = useRef(null);

  const handleScroll = () => {
    if (
      statsRef.current &&
      window.scrollY + window.innerHeight >= statsRef.current.offsetTop
    ) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const customersIncrement = Math.ceil(
        (targetCustomers - currentCustomers) / (duration * 10)
      );
      const countriesIncrement = Math.ceil(
        (targetCountries - currentCountries) / (duration * 10)
      );
      const projectsIncrement = Math.ceil(
        (targetProjects - currentProjects) / (duration * 10)
      );

      const intervalId = setInterval(() => {
        setCurrentCustomers((value) =>
          Math.min(value + customersIncrement, targetCustomers)
        );
        setCurrentCountries((value) =>
          Math.min(value + countriesIncrement, targetCountries)
        );
        setCurrentProjects((value) =>
          Math.min(value + projectsIncrement, targetProjects)
        );
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, [
    isVisible,
    customers,
    countries,
    projects,
    duration,
    currentCustomers,
    currentCountries,
    currentProjects,
    targetCustomers,
    targetCountries,
    targetProjects,
  ]);

  return (
    <div
      className="stats-section w-full h-screen max-h-[50rem] p-8 sm:p-12 md:p-16 lg:p-24 relative mb-32 block z-5"
      ref={statsRef}
    >
      <div className="top2">
        <div className="flex justify-center">
          <div className="w-8 sm:w-12 border-b-2 sm:border-b-4 border-blue-900"></div>
        </div>
        <h2 className="mt-4 mx-6 sm:mx-12 text-center text-lg sm:text-xl lg:text-2xl font-semibold text-blue-900">
          {stats_page_title}
        </h2>
      </div>
      <section className="text-gray-700 body-font">
        <div className="container px-5 py-12 mx-auto">
          <div className="flex flex-wrap -m-4 text-center">
            <div className="p-4 md:w-1/4 sm:w-1/2 w-full md:flex">
              <div className="h-full border-2 border-gray-600 px-4 py-6 rounded-lg">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="text-blue-700 w-12 h-12 mb-3 inline-block"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                  />
                </svg>
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  {stats_section_card4_title}
                </h2>
                <p className="leading-relaxed">{stats_section_card4_text}</p>
              </div>
            </div>
            <div className="p-4 md:w-1/4 sm:w-1/2 w-full md:flex">
              <div className="h-full border-2 border-gray-600 px-4 py-6 rounded-lg">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="text-blue-700 w-12 h-12 mb-3 inline-block"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                  />
                </svg>
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  {stats_section_card5_title}
                </h2>
                <p className="leading-relaxed">{stats_section_card5_text}</p>
              </div>
            </div>
            <div className="p-4 md:w-1/4 sm:w-1/2 w-full md:flex">
              <div className="h-full border-2 border-gray-600 px-4 py-6 rounded-lg">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="text-blue-700 w-12 h-12 mb-3 inline-block"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
                  />
                </svg>
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  {stats_section_card6_title}
                </h2>
                <p className="leading-relaxed">{stats_section_card6_text}</p>
              </div>
            </div>
            <div className="p-4 md:w-1/4 sm:w-1/2 w-full md:flex">
              <div className="h-full border-2 border-gray-600 px-4 py-6 rounded-lg">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="text-blue-700 w-12 h-12 mb-3 inline-block"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z"
                  />
                </svg>
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  {stats_section_card7_title}
                </h2>
                <p className="leading-relaxed">{stats_section_card7_text}</p>
              </div>
            </div>
            <div className="p-4 md:w-1/4 sm:w-1/2 w-full hidden md:block">
              <div className="h-full border-2 border-gray-600 px-4 py-6 rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  class="text-blue-700 w-12 h-12 mb-3 inline-block"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                  />
                </svg>
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  {currentProjects}
                </h2>
                <p className="leading-relaxed">{stats_projects}</p>
              </div>
            </div>
            <div className="p-4 md:w-1/4 sm:w-1/2 w-full hidden md:block">
              <div className="h-full border-2 border-gray-600 px-4 py-6 rounded-lg">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="text-blue-700 w-12 h-12 mb-3 inline-block"
                  viewBox="0 0 24 24"
                >
                  <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                </svg>
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  {currentCustomers}
                </h2>
                <p className="leading-relaxed">{stats_customers}</p>
              </div>
            </div>
            <div className="p-4 md:w-1/4 sm:w-1/2 w-full hidden md:block">
              <div className="h-full border-2 border-gray-600 px-4 py-6 rounded-lg">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="text-blue-700 w-12 h-12 mb-3 inline-block"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                  />
                </svg>
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  20+
                </h2>
                <p className="leading-relaxed">{stats_years}</p>
              </div>
            </div>
            <div className="p-4 md:w-1/4 sm:w-1/2 w-full hidden md:block">
              <div className="h-full border-2 border-gray-600 px-4 py-6 rounded-lg">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="text-blue-700 w-12 h-12 mb-3 inline-block"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m20.893 13.393-1.135-1.135a2.252 2.252 0 0 1-.421-.585l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 0 1-1.383-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.411-2.353a2.25 2.25 0 0 0 .286-.76m11.928 9.869A9 9 0 0 0 8.965 3.525m11.928 9.868A9 9 0 1 1 8.965 3.525"
                  />
                </svg>
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  10+
                </h2>
                <p className="leading-relaxed">{stats_countries}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StatsSection;
