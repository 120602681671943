import React from "react";
import "pages/About/About.css";
import { translate } from "components/Translation/Translation";
import boss from "images/serkan.png";
const linkedinLogo = `${process.env.PUBLIC_URL}/linkedin-50.svg`;

function About() {
  const linkedin_url =
    "https://www.linkedin.com/company/epok-geomatics-international/";

  return (
    <div id="about" className="about ">
      <div className="flex justify-center">
        <div className="w-24 border-b-4 border-blue-900"></div>
      </div>
      <h2 className="mt-4 text-center text-xl lg:text-2xl font-semibold text-blue-900">
        {translate("about.about_title")}
      </h2>
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="max-w-md mx-auto">
          <div className="bg-white rounded-xl shadow-md overflow-hidden">
            <img
              className="w-full h-auto object-cover"
              src={boss}
              alt="Serkan Özçelik"
            />
            <div className="p-6 text-center">
              <div className="text-indigo-500 font-semibold">
                Serkan Özçelik
              </div>
              <p className="text-gray-600">{translate("about.boss_title")}</p>
              <a
                href={linkedin_url}
                target="_blank"
                rel="noopener noreferrer"
                className="block mt-2 text-black hover:underline"
              >
                <img
                  src={linkedinLogo}
                  alt="LinkedIn"
                  className="h-6 w-6 inline-block"
                />
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className="text-center">
            <p>{translate("about.about_description")}</p>
          </div>
          <div className="text-center mt-8">
            <p>{translate("about.about_description_2")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
