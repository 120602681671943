import React, { useRef, useEffect } from "react";
import "./Slider.css";
import arcadis from "images/logos/arcadis.jpg";
import arel from "images/logos/arel.jpg";
import arisu from "images/logos/arisu.jpg";
import asar from "images/logos/asar.jpg";
import asat from "images/logos/asat.png";
import azersu from "images/logos/azersu.png";
import batuner from "images/logos/batuner.jpg";
import bodrumbel from "images/logos/bodrum.png";
import burostatik from "images/logos/burostatik.jpg";
import coante from "images/logos/coante.jpg";
import dc from "images/logos/dc.jpg";
import derya from "images/logos/derya.jpg";
import dsi from "images/logos/dsi.jpg";
import ekare from "images/logos/ekare.jpg";
import emlakkonutgmyo from "images/logos/emlakkonut.png";
import enye from "images/logos/enye.jpg";
import ermas from "images/logos/ermas.jpg";
import eruzurumbuyuksehir from "images/logos/erzurumbuyuksehir.jpg";
import etap from "images/logos/etap.jpg";
import fieldtech from "images/logos/fieldtech.jpg";
import gaziantepbuyuksehir from "images/logos/gaziantepbuyuksehir.jpg";
import geotech from "images/logos/geotech.jpg";
import hidrolayihe from "images/logos/hidrolayihe.jpg";
import ic from "images/logos/ic.jpg";
import ilbank from "images/logos/ilbank.jpg";
import ilgar from "images/logos/ilgar.png";
import kale from "images/logos/kale.png";
import kanalsu from "images/logos/kanalsu.jpg";
import karaosmanoglu from "images/logos/karaosmanoglu.png";
import kentmer from "images/logos/kentmer.jpg";
import kgm from "images/logos/kgm.jpg";
import mapeg from "images/logos/mapeg.jpg";
import marmarisbelediye from "images/logos/marmarisbelediye.jpg";
import mentese from "images/logos/mentese.jpg";
import mescioglu from "images/logos/mescioglu.png";
import msku from "images/logos/msku.png";
import muglabuyuksehir from "images/logos/muglabuyuksehir.jpg";
import muski from "images/logos/muski.jpg";
import nwc from "images/logos/nwc.jpg";
import odak from "images/logos/odak.jpg";
import ozaltin from "images/logos/ozaltin.jpg";
import ozbek from "images/logos/ozbek.jpg";
import polikay from "images/logos/polikay.jpg";
import promota from "images/logos/promota.jpg";
import pusula from "images/logos/pusula.jpg";
import radyan from "images/logos/radyan.jpg";
import solarlife from "images/logos/solarlife.jpg";
import suyapi from "images/logos/suyapi.png";
import tcdd from "images/logos/tcdd.png";
import tubitak from "images/logos/tubitak.jpg";
import tvthidrotek from "images/logos/tvthidrotek.jpg";
import yapimerkezi from "images/logos/yapimerkezi.jpg";
import yatagan from "images/logos/yatagan.jpg";

const Slider = () => {
  const logosRef = useRef(null);

  useEffect(() => {
    const logos = logosRef.current;
    const logoWidth = logos.children[0].offsetWidth;
    const totalWidth = logoWidth * logos.children.length;
    logos.style.animationDuration = `${totalWidth / 100}px`;
  }, []);

  return (
    <div className="slider">
      <div className="logos-slide" ref={logosRef}>
        <img
          src={arcadis}
          alt="Arcadis Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={arel}
          alt="Arel Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={arisu}
          alt="Arisu Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={asar}
          alt="Asar Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={asat}
          alt="Asat Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={azersu}
          alt="Azersu Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={batuner}
          alt="Batuner Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={bodrumbel}
          alt="Bodrum Bel Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={burostatik}
          alt="Burostatik Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={coante}
          alt="Coante Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={dc}
          alt="DC Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={derya}
          alt="Derya Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={dsi}
          alt="DSI Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={ekare}
          alt="Ekare Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={emlakkonutgmyo}
          alt="Emlak Konut GMYO Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={enye}
          alt="Enye Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={ermas}
          alt="Ermas Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={eruzurumbuyuksehir}
          alt="Erzurum Buyuksehir Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={etap}
          alt="Etap Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={fieldtech}
          alt="Field Tech Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={gaziantepbuyuksehir}
          alt="Gaziantep Buyuksehir Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={geotech}
          alt="Geotech Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={hidrolayihe}
          alt="Hidrolayihe Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={ic}
          alt="IC Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={ilbank}
          alt="Ilbank Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={ilgar}
          alt="Ilgar Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={kale}
          alt="Kale Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={kanalsu}
          alt="Kanalsu Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={karaosmanoglu}
          alt="Karaosmanoglu Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={kentmer}
          alt="Kentmer Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={kgm}
          alt="KGM Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={mapeg}
          alt="Mapeg Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={marmarisbelediye}
          alt="Marmaris Belediye Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={mentese}
          alt="Mentese Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={mescioglu}
          alt="Mescioglu Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={msku}
          alt="Msku Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={muglabuyuksehir}
          alt="Mugla Buyuksehir Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={muski}
          alt="Muski Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={nwc}
          alt="NWC Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={odak}
          alt="Odak Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={ozaltin}
          alt="Ozaltin Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={ozbek}
          alt="Ozbek Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={polikay}
          alt="Polikay Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={promota}
          alt="Promota Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={pusula}
          alt="Pusula Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={radyan}
          alt="Radyan Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={solarlife}
          alt="Solar Life Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={suyapi}
          alt="Suyapi Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={tcdd}
          alt="TCDD Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={tubitak}
          alt="Tubitak Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={tvthidrotek}
          alt="TVT Hidrotek Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={yapimerkezi}
          alt="Yapi Merkezi Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
        <img
          src={yatagan}
          alt="Yatagan Logo"
          className="object-contain w-10 h-10 sm:w-full sm:h-auto"
        />
      </div>
    </div>
  );
};

export default Slider;
